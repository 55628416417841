import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/components/Layout/Login.vue'),
  },
  {
    path: '/client',
    name: 'client',
    component: () => import('@/components/ClientPanel/ClientPanel.vue'),
    children: [
      {
        path: 'my-company',
        name: 'my-company',
        component: () => import('@/components/ClientPanel/Company/MyCompany.vue'),
        children: [
          {
            path: 'branding',
            name: 'my-company.branding',
            component: () => import('@/components/ClientPanel/Branding.vue'),
          },
          {
            path: 'info',
            name: 'my-company.info',
            component: () => import('@/components/ClientPanel/Company/MyCompanyInfo.vue'),
          },
          {
            path: 'business-symbol',
            name: 'my-company.business-symbol',
            component: () => import('@/components/ClientPanel/Company/BusinessSymbol/BusinessSymbol.vue'),
          },
          {
            path: 'shareholders',
            name: 'my-company.shareholders',
            component: () => import('@/components/ClientPanel/Company/Shareholder/Shareholders.vue'),
          },
          {
            path: 'accounting',
            name: 'my-company.accounting',
            component: () => import('@/components/ClientPanel/Company/MyCompanyAccounting.vue'),
          },
          {
            path: 'attachment',
            name: 'my-company.attachment',
            component: () => import('@/components/ClientPanel/Company/MyCompanyAttachments.vue'),
          },
          {
            path: 'seasonal-form',
            name: 'my-company.seasonal-form',
            component: () => import('@/components/ClientPanel/Company/SeasonalForm/SeasonalForms.vue'),
          },
          {
            path: 'residence',
            name: 'my-company.residence',
            component: () => import('@/components/ClientPanel/Company/Residence/Residence.vue'),
          },
          {
            path: 'social-security',
            name: 'my-company.social-security',
            component: () => import('@/components/ClientPanel/Company/SocialSecurity/SocialSecurity.vue'),
          },
        ],
      },
      {
        path: 'profile',
        name: 'client-profile',
        component: () =>
          import('@/components/ClientPanel/Profile/ClientProfile.vue'),
      },
    ],
  },

  // admin panel routes
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/components/Layout/AdminLogin.vue'),
  },
  {
    path: '/admin-panel',
    name: 'admin-panel',
    component: () => import('@/components/AdminPanel/AdminPanel.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'admin-panel.dashboard',
        component: () => import('@/components/AdminPanel/Dashboard.vue'),
      },
      {
        path: 'company',
        name: 'admin-panel.company',
        component: () => import('@/components/AdminPanel/Company/Companies.vue'),
      },
      {
        path: 'company/:id',
        name: 'view-company',
        params: { id: 0 },
        component: () => import('@/components/AdminPanel/Company/View.vue'),
        children: [
          {
            path: '',
            name: 'view-company.info',
            component: () => import('@/components/AdminPanel/Company/Info.vue'),
          },
          {
            path: 'business-symbol',
            name: 'view-company.business-symbol',
            component: () => import('@/components/AdminPanel/Company/BusinessSymbol/BusinessSymbol.vue'),
          },
          {
            path: 'shareholders',
            name: 'view-company.shareholders',
            component: () => import('@/components/AdminPanel/Company/Shareholder/Shareholders.vue'),
          },
          {
            path: 'accounting',
            name: 'view-company.accounting',
            component: () => import('@/components/AdminPanel/Company/Accounting/CompanyAccounting.vue'),
          },
          {
            path: 'attachment',
            name: 'view-company.attachment',
            component: () => import('@/components/AdminPanel/Company/CompanyAttachments.vue'),
          },
          {
            path: 'seasonal-form',
            name: 'view-company.seasonal-form',
            component: () => import('@/components/AdminPanel/Company/SeasonalForm/SeasonalForms.vue'),
          },
          {
            path: 'residence',
            name: 'view-company.residence',
            component: () => import('@/components/AdminPanel/Company/Residence/Residence.vue'),
          },
          {
            path: 'social-security',
            name: 'view-company.social-security',
            component: () => import('@/components/AdminPanel/Company/SocialSecurity/SocialSecurity.vue'),
          },
          {
            path: 'lawyer-contract',
            name: 'view-company.lawyer-contract',
            component: () => import('@/components/AdminPanel/Company/LawyerContract/LawyerContract.vue'),
          },
        ],
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('@/components/AdminPanel/User/Users.vue'),
      },
      {
        path: 'profile',
        name: 'admin-profile',
        component: () => import('@/components/AdminPanel/User/Profile.vue'),
      },
    ],
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('@/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
